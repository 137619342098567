import React from "react"
import { Link } from 'gatsby'

import * as NaviStyle from './navigation.module.scss'

const Navigation = () => {
  React.useEffect(() => {
    const removeNav = () => {
      document.getElementById("check").checked = false;
    }
    document.querySelector('main').addEventListener('click', removeNav);
  }, [])

  return (
    <div className={`${NaviStyle.navcontainer} container p-4 relative mt-4 flex flex-col xl:flex-row`} role="navigation" aria-label="Navigation">
      <Link className={`${NaviStyle.logo} absolute left-1 xl:relative inline-block z-50`} to="/">Startseite</Link>
      <input id="check" className={`${NaviStyle.check} hidden`} type="checkbox" />
      <label style={{ top: "-10px", left: "5px" }} htmlFor="check" className="relative inline-block self-end xl:hidden z-50 cursor-pointer">
        <svg className={`${NaviStyle.icon}`} viewBox="170 110 500 400">
          <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" className={`${NaviStyle.top}`}></path>
          <path d="M300,320 L540,320" className={`${NaviStyle.middle}`}></path>
          <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" className={`${NaviStyle.bottom}`} transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
        </svg>
      </label>
      <nav className={`${NaviStyle.nav} absolute left-0 top-0 opacity-0 w-full xl:opacity-100 flex flex-col justify-center z-40 xl:flex-row xl:relative xl:justify-between xl:h-auto`}>
        <ul className="text-center hidden flex-col xl:flex xl:flex-row xl:ml-8 xl:items-center">
          <li><Link className="text-2xl py-4 xl:py-0 xl:mr-8 xl:text-base" activeClassName={NaviStyle.active} to="/who-we-are">Who we are</Link></li>
          <li><Link className="text-2xl py-4 xl:py-0 xl:mr-8 xl:text-base" activeClassName={NaviStyle.active} to="/our-mission">Our Mission</Link></li>
          <li><Link className="text-2xl py-4 xl:py-0 xl:mr-8 xl:text-base" activeClassName={NaviStyle.active} to="/engineering">Engineering</Link></li>
          <li><Link className="text-2xl py-4 xl:py-0 xl:mr-8 xl:text-base" activeClassName={NaviStyle.active} to="/data">Data</Link></li>
          <li><Link className="text-2xl py-4 xl:py-0 xl:mr-8 xl:text-base" activeClassName={NaviStyle.active} to="/ui-ux">UI &amp; UX</Link></li>
        </ul>

        <ul className="text-center hidden flex-col xl:flex xl:flex-row xl:items-center">
          <li><Link className="text-2xl py-4 xl:py-0 xl:mr-8 xl:text-base" partiallyActive={true} activeClassName={NaviStyle.active} to="/blog">Blog</Link></li>
          <li><Link className="text-2xl py-4 xl:py-0 xl:text-base" partiallyActive={true} activeClassName={NaviStyle.active} to="/jobs">Jobs</Link></li>
        </ul>
      </nav>

      <div className={`${NaviStyle.background} block xl:hidden z-30`}></div>
    </div>
  )
}

export default Navigation
