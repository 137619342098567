import React from "react"
import Helmet from 'react-helmet'

/* Components */
import Navigation from 'components/navigation'
import Footer from 'components/footer'

const BaseLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="de" />
      </Helmet>
      <Navigation />
      <main>
        { children }
      </main>
      <Footer />
    </>
  )
}

export default BaseLayout
