import React from "react"

const ComponentLayout = (props) => {
  return (
    <section className={`${props.className} container grid grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-8 px-4`}>
      { props.children }
    </section>
  )
}

export default ComponentLayout
