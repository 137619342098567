import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon from "../images/favicon.png";

const SEO = ({title, description, image, url, schemaMarkup}) => {
  const { site, preview } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `https:${preview.file.url}`,
    url: url
  }

  return (
    <Helmet
      title={`${seo.title} | jobvalley Tech`}
      meta={[
        { name: "description", content: `${seo.description}` },

        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:site", content: "@jobvalleytech" },
        { name: "twitter:creator", content: "@jobvalleytech" },
        { name: "twitter:url", content: `${seo.url}`},
        { name: "twitter:title", content: `${seo.title}`},
        { name: "twitter:description", content: `${seo.description}` },
        { name: "twitter:image", content: `${seo.image}`},

        { property: "og:description", content: `${seo.description}`},
        { property: "og:image", content: `${seo.image}` },
        { property: "og:image:width", content: "1200" },
        { property: "og:image:height", content: "630" },
        { property: "og:site_name", content: "jobvalley.tech"},
        { property: "og:title", content: `${seo.title}`},
        { property: "og:url", content: `${seo.url}`},
      ]}
      link={[
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon}` }
      ]}
    >
    {schemaMarkup && <script type="application/ld+json">
      {JSON.stringify(schemaMarkup)}
    </script>}
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
      }
    }

    preview: contentfulAsset(title: {eq: "social media preview"}) {
      file {
        url
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

SEO.defaultProps = {
  title: null,
  description: null
}
