
import React from "react"

/* Base Component */
import ComponentLayout from 'layouts/component.layout'

/* Styles */
import './footer.component.scss'

/* Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false
library.add(
  faTwitter,
  faInstagram,
  faFacebookF
)

const Footer = () => {
  return (
    <>
      <svg className="min-w-full" width="100%" height="100%" viewBox="0 0 6000 534">
        <path className="wave" d="M0,0l6000,0l0,352.756c-452.778,120.385 -837.5,180.577 -1154.17,180.577c-1420.85,0 -1420.85,-503.937 -3070.83,-503.937c-681.467,0 -1273.13,107.787 -1775,323.36l0,-352.756Z" />
      </svg>
      <ComponentLayout className="grid-flow-row">
        <nav className="flex items-center justify-between flex-wrap py-8 col-span-2 md:col-span-6 lg:col-span-12">
          <div className="footer-nav w-full flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="flex justify-around text-sm md:text-base lg:block lg:flex-grow">
              <a className="block mt-4 lg:inline-block lg:mt-0 lg:mr-8 text-center" rel="noreferrer" target="_blank" href="https://jobvalley.com/impressum/">Impressum</a>
              <a className="block mt-4 lg:inline-block lg:mt-0 lg:mr-8 text-center" rel="noreferrer" target="_blank" href="https://jobvalley.com/datenschutzerklaerung/">Datenschutz</a>
            </div>
            <div className="flex justify-center lg:block">
              <a className="block mt-4 lg:inline-block lg:mt-0 lg:mr-8 text-center" rel="noreferrer" target="_blank" href="https://www.facebook.com/jobvalleyDE">
                <FontAwesomeIcon className="text-white" icon={['fab', 'facebook-f']} />
              </a>
              <a className="mx-4 block mt-4 lg:inline-block lg:mx-0 lg:mt-0 lg:mr-8 text-center" rel="noreferrer" target="_blank" href="https://twitter.com/jobvalley_de">
                <FontAwesomeIcon className="text-white" icon={['fab', 'twitter']} />
              </a>
              <a className="block mt-4 lg:inline-block lg:mt-0 text-center" rel="noreferrer" target="_blank" href="https://www.instagram.com/jobvalleyde/">
                <FontAwesomeIcon className="text-white" icon={['fab', 'instagram']} />
              </a>
            </div>
          </div>
        </nav>
      </ComponentLayout>
    </>
  )
}

export default Footer
