// extracted by mini-css-extract-plugin
export var background = "navigation-module--background--3QbFk";
export var logo = "navigation-module--logo--dOBN5";
export var check = "navigation-module--check--2Uc8H";
export var nav = "navigation-module--nav--25V8n";
export var navigationslide = "navigation-module--navigationslide--2sCrG";
export var top = "navigation-module--top--uFrzi";
export var bottom = "navigation-module--bottom--3AzHp";
export var middle = "navigation-module--middle--CVJB2";
export var active = "navigation-module--active--2YJda";
export var navcontainer = "navigation-module--navcontainer--3iHAU";
export var icon = "navigation-module--icon--1KITk";